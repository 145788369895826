import React from 'react';
import '../assets/styles/TeamMemberCard.css';
import headshotPlaceholder from '../assets/images/headshot-placeholder.png'; // Placeholder image

const TeamMemberCard = ({ name, title, bio, email, linkedin, image = headshotPlaceholder }) => (
  <div className="team-member-card">
    <img src={image} alt={`${name}'s photo`} className="team-member-image" />
    <h3 className="team-member-name">{name}</h3>
    <p className="team-member-title">{title}</p>
    <div className="team-member-bio">{bio}</div> {/* Updated to render JSX in bio */}
    <div className="team-member-links">
      <a href={`mailto:${email}`} className="email-link" aria-label="Email">
        ✉️
      </a>
      <a href={linkedin} target="_blank" rel="noopener noreferrer" className="linkedin-link" aria-label="LinkedIn">
        🔗
      </a>
    </div>
  </div>
);

export default TeamMemberCard;
