import React from 'react';
import '../assets/styles/VideoBackground.css';
import videoSource from '../assets/videos/Monument.mp4';

const VideoBackground = () => (
  <div className="video-background">
    <video autoPlay loop muted playsInline className="video-content">
      <source src={videoSource} type="video/mp4" />
      Your browser does not support the video tag.
    </video>
    <div className="overlay">
      <h1>Boutique Healthcare AI Consulting in Our Nation's Capital</h1>
      <p>Fast-tracking you into an AI-enabled future with the deep clinical subject matter expertise that today's market requires. Virtual consultations available.</p>
    </div>
  </div>
);

export default VideoBackground;
