import React from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import '../assets/styles/TestimonialSlider.css';
import headshotPlaceholder1 from '../assets/images/headshot-placeholder1.png';
import headshotPlaceholder2 from '../assets/images/headshot-placeholder2.png';
import headshotPlaceholder3 from '../assets/images/headshot-placeholder3.png';
import headshotPlaceholder4 from '../assets/images/headshot-placeholder4.png';

const testimonials = [
  {
    text: "Dr. MacLean and her team provided invaluable expertise, helping us refine our AI implementation strategy to better align with clinical workflows. Their ability to anticipate challenges and deliver actionable solutions was unmatched. Our startup is now well-positioned to achieve our goals, thanks to their guidance.",
    author: "James King",
    position: "Chief Executive Officer",
    image: headshotPlaceholder1
  },
  {
    text: "Dr. MacLean’s thorough understanding of healthcare AI made her team's consulting service a standout. Dr. MacLean was incredibly detail-oriented, and Jay even reviewed our codebase to offer a healthcare data scientist's perspective. Their insights helped us avoid costly mistakes and ensured our investments were focused on scalable, impactful solutions.",
    author: "Michael Patel",
    position: "Venture Capitalist",
    image: headshotPlaceholder2
  },
  {
    text: "The advice and support we received from Dr. MacLean were instrumental in our success. Her deep expertise in radiology and AI-driven solutions helped us navigate the complexities of integrating new technologies into our hospital system. The seamless implementation exceeded our expectations, saving us time and resources.",
    author: "Sarah Reyes",
    position: "Healthcare Executive",
    image: headshotPlaceholder3
  },
  {
    text: "Jay Gopal, Will Borges, and the whole team in the Become A Healthcare Leader program are absolutely phenomenal career coaches. Their mentorship gave me the tools, confidence, and clarity I needed to navigate medical school and beyond. I would highly recommend their program to anyone looking to achieve their career goals in healthcare.",
    author: "Emily Chen",
    position: "Combined Medical Program Student",
    image: headshotPlaceholder4
  }
];

// Custom arrow components
const NextArrow = ({ onClick }) => (
  <div className="arrow next-arrow" onClick={onClick}>
    &#10095;
  </div>
);

const PrevArrow = ({ onClick }) => (
  <div className="arrow prev-arrow" onClick={onClick}>
    &#10094;
  </div>
);

const TestimonialSlider = () => {
  const settings = {
    dots: false,  // Hide dots
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />
  };

  return (
    <div className="testimonial-slider">
      <h2>Discover What Our Clients Are Saying</h2>
      <Slider {...settings}>
        {testimonials.map((testimonial, index) => (
          <div key={index} className="testimonial-slide">
            <p className="testimonial-text">"{testimonial.text}"</p>
            <div className="testimonial-author">
              <img src={testimonial.image} alt={testimonial.author} className="author-image" />
              <div>
                <h4>{testimonial.author}</h4>
                <p className="author-position">{testimonial.position}</p>
              </div>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default TestimonialSlider;
