import React from 'react';
import VideoBackground from '../components/VideoBackground';
import TestimonialSlider from '../components/TestimonialSlider';
import Button from '../components/Button';

const Home = () => (
  <div className="home-container">
    <VideoBackground />

    {/* Intro Section */}
    <section className="intro-section">
      <p>
        The most trusted physician-owned advising and consulting firm with 100+ experienced physician, nurse, and data scientist advisors. Our team brings medical specialty expertise across 25+ clinical areas, available on an hourly basis or for longer-term projects.
      </p>
      <p>
        Specialty Expertise in Products and Implementation: Radiology Imaging, Cardiology Diagnostics, Ophthalmology, Ambient Listening (AI Scribe), EHR AI, Vaccines, Behavioral Health, Rheumatology, Gastrointestinal Diseases, Dermatology, Neurology, Cardiothoracic AI, Endocrinology, Government Relations, Clinical Trials, Oncology, and Population Health.
      </p>
      <Button text="Get Started" link="https://calendly.com/ainsleymaclean/30min" />
    </section>

    {/* Features Section */}
    <section className="features-section">
      <h2>Why Choose Ainsley MacLean & Company?</h2>
      <div className="features-grid">
        <div className="feature-item">
          <h3>Comprehensive Expertise</h3>
          <p>We bring years of experience across all healthcare specialties.</p>
        </div>
        <div className="feature-item">
          <h3>Personalized Service</h3>
          <p>Customized strategies tailored to your company's value proposition and customer needs.</p>
        </div>
        <div className="feature-item">
          <h3>Guidance in Regulated Healthcare</h3>
          <p>Helping you navigate the complexities of AI implementation in today's regulated healthcare space.</p>
        </div>
      </div>
      
    </section>
    {/* Testimonials */}
    <TestimonialSlider />

    {/* Capabilities Section */}
    <section className="capabilities-section">
    <h2>Our Firm's Core</h2>
      <div className="features-grid">
        <div className="feature-item">
          <h3>Vision Statement</h3>
          <p>We aim to be the foremost leader in boutique healthcare advising, enabling advancements through expert physician consultation and accelerating growth in the healthcare sector globally.</p>
        </div>
        <div className="feature-item">
          <h3>Mission Statement</h3>
          <p>We empower healthcare entities by providing unmatched physician-driven clinical expertise, strategic advisory, and actionable insights that improve patient outcomes.</p>
        </div>
      </div>
      <br /><br />
    </section>

    {/* Call-to-Action Section */}
    <section className="call-to-action-section">
      <h2>Ready to Elevate Your Healthcare Strategy?</h2>
      <Button text="Get Started" link="https://calendly.com/ainsleymaclean/30min" /> 
    </section>
  </div>
);

export default Home;
