import React from 'react';
import TeamMemberCard from '../components/TeamMemberCard';
import '../assets/styles/About.css';
import AinsleyMacLeanHeadshot from '../assets/images/ainsley-maclean.jpg'; 

import JayGopalHeadshot from '../assets/images/jay-gopal.jpg';
import WillBorgesHeadshot from '../assets/images/will-borges.jpeg'; 


const About = () => (
  <div className="about-container">
    <h1>About Us</h1>
    <div className="team-grid">
      <TeamMemberCard 
        name="Ainsley MacLean, M.D." 
        image={AinsleyMacLeanHeadshot}
        title="Senior Partner, Founder" 
        bio={
          <>
            Dr. Ainsley MacLean is a seasoned healthcare technology executive and radiology operations
            leader with over 20 years in the field. A Harvard-trained neuroradiologist, she started her
            career at Brigham and Women's Hospital, where she served as chief resident and chief neuroradiology
            fellow. Most recently, she was the Chief AI Officer and Chief Medical Information Officer at Kaiser
            Permanente in Washington, D.C. At Kaiser Permanente, Dr. MacLean led the strategy and implementation of
            AI-enabled technologies across an enterprise serving 12.5 million patients, including the largest deployment
            of generative AI in healthcare to date.

            Dr. MacLean has served as principal investigator for clinical studies in breast cancer diagnostic technology and AI,
            and has advised venture capital firms on early selection and due diligence. A contributor to Forbes, she has authored
            several articles on AI in healthcare and is an advisor to Carna Health, a startup focused on preventing chronic kidney 
            disease worldwide. She is an affiliate of SignalFire, an AI-driven venture capital fund, where she lends her expertise to 
            support innovative healthcare ventures.

            Dr. MacLean has supported numerous startups in becoming enterprise-ready and practical for today’s healthcare environment.
            She is a graduate of Harvard Business School’s Executive Leadership Program and is a former board member of Halycon,
            the National Women's History Museum, and Compass Pro Bono. Dr. MacLean holds degrees from Brown University and the Warren Alpert Medical School
            and is a member of the Brown University Angel Investors Group. She is on the Advisory Board of the American Board of Artificial Intelligence in Medicine. 
            Dr. MacLean has led teams and helped to support hundreds of physicians and other practitioners in achieving fluency and operationalization of AI in the 
            real world hospital and clinic setting.
            
            Dr. MacLean is a third generation physician. As an undergraduate, she first became interested in the business of medicine when she spent a summer 
            in the pharmaceutical clinical trial space at Pfizer’s research facility in Groton, CT. She subsequently chose the specialty of radiology after a summer 
            at UCSF working under the lab of Dr. Harry Genant, one of the fathers of musculoskeletal imaging, in bone fracture healing. Her passion for neuroradiology 
            was a natural progression as the granddaughter of Dr. Paul MacLean, an internist who led teams at Yale and the National Institute of Mental Health (NIMH) here 
            in Washington, DC and is considered by many to have been the first to describe the functioning of the limbic system within the brain.
            <br /><br />
            
            <strong>Selected areas of expertise:</strong> imaging, radiology, venture capital due diligence, ambient listening, EHR AI, breast cancer, leadership development, healthcare startup ecosystem, machine learning, data science, computer vision, revenue cycle management, billing
          </>
        }
        email="ainsleymaclean@gmail.com" 
        linkedin="https://www.linkedin.com/in/ainsley-maclean-m-d-162b3636/" 
      />

      <TeamMemberCard 
        name="Jay Gopal" 
        image={JayGopalHeadshot}
        title="Partner, Co-Founder" 
        bio={
          <>

            Jay Gopal is co-founder and CEO of Kyron Medical, bringing over eight years of expertise 
            in AI-driven healthcare solutions. He is a student in Brown University’s Program in Liberal Medical Education (PLME), 
            where he studies computational neuroscience and is an M.D. Candidate. Jay has led advanced AI initiatives at institutions 
            such as Kaiser Permanente in Washington, D.C., Johns Hopkins University, and Stanford Medicine, focusing on predictive analytics, 
            clinical diagnostics, and explainable machine learning. His research includes pioneering new deep learning model training schemes with Brown University's Serre Lab,
            DARPA-supported spinal interface projects at Brown’s Center for Computational Brain Science, pioneering neuromodulation technologies with Stanford’s Precision Neurotherapeutics Lab, and 
            advanced counterfactual machine learning explanations with Brown’s Center for Computational Molecular Biology. Jay has also collaborated with the 
            United States Department of Energy on long-term precision AI applications, positioning him at the forefront of medical AI innovation in government, 
            industry, and academia.
            Jay is board-certified by the American Board of Artificial Intelligence in Medicine. His work spans specialties including cardiology, neurosurgery, 
            plastic surgery, ophthalmology, radiology, and psychiatry, with numerous national conference presentations and peer-reviewed publications. Committed 
            to empowering future physician leaders, he has advised and mentored dozens of students working with Brown University's Nelson Center for Entrepreneurship. 
            Jay also brings extensive experience in venture capital due 
            diligence and consults on AI integration strategies for healthcare startups and medical institutions.
                        

            <br /><br />
            
            <strong>Selected areas of expertise:</strong> EHR AI, computer vision, future physician leader development, venture capital due diligence, healthcare startup ecosystem, machine learning, founding companies, 
            market research, data science, revenue cycle management, billing, personalized medicine
          </>
        }
        email="jayrgopal1@gmail.com" 
        linkedin="https://www.linkedin.com/in/jay-gopal/" 
      />
      <TeamMemberCard 
        name="Will Borges" 
        image={WillBorgesHeadshot}
        title="Partner, Co-Founder" 
        bio={
          <>
            Will Borges is an M.S. student in Personalized Medicine and Applied Engineering at Yale University, the nation’s first program of its kind. He is also a Roberts Innovation Fund Associate 
            at Yale Ventures, helping Yale engineering faculty bring innovations to market. Will earned an Sc.B. in Biochemistry and Molecular Biology with honors, along with a certificate in 
            entrepreneurship from Brown University. At Brown, he founded the Brown Medical Entrepreneurship Society, held leadership roles in the Brown Entrepreneurship Program and Brown Healthcare 
            Investment Group, and served as a First Year Experience Fellow on the Orientation Welcoming Committee. As a senior teaching assistant at Brown, Will supported students in cell and molecular biology, 
            genetics, immunology, and entrepreneurial management. He also served as an academic coach for premed students at the Curricular Resource Center. Passionate about student entrepreneurship, Will co-founded 
            the nonprofit NextGen Entrepreneurship Network (NGEN), which connects Ivy+ student entrepreneurs through programming in NYC. Will has conducted research at Brown, Yale, Cold Spring Harbor Laboratory, 
            and Stony Brook University, resulting in 10 peer-reviewed publications and him being named a Regeneron Science Talent Search Scholar. Early admitted to the Icahn School of Medicine at Mount Sinai through 
            the FlexMed program as a college sophomore, Will plans to pursue his M.D. after completing graduate school at Yale. He is dedicated to advancing medicine, science, and entrepreneurship, and empowering the 
            next generation of leaders.

            <br /><br />
            
            <strong>Selected areas of expertise:</strong> entrepreneurship, startups, venture capital, market research, nanomedicine, biotechnology, immunotherapies, cancer biology, personalized medicine, bioengineering, 
            medical education, health equity, future medical leader development
          </>
        } 
        email="willborges20@gmail.com" 
        linkedin="https://www.linkedin.com/in/william-borges-02697b189/" 
      />
      <TeamMemberCard name="Person2" title="Consultant" bio="Bio for Person2 goes here." email="ainsleymaclean@gmail.com" linkedin="https://www.linkedin.com/in/ainsley-maclean-m-d-162b3636/" />
      <TeamMemberCard name="Person3" title="Consultant" bio="Bio for Person3 goes here." email="ainsleymaclean@gmail.com" linkedin="https://www.linkedin.com/in/ainsley-maclean-m-d-162b3636/" />
      <TeamMemberCard name="Person4" title="Consultant" bio="Bio for Person4 goes here." email="ainsleymaclean@gmail.com" linkedin="https://www.linkedin.com/in/ainsley-maclean-m-d-162b3636/" />
      <TeamMemberCard name="Person5" title="Consultant" bio="Bio for Person5 goes here." email="ainsleymaclean@gmail.com" linkedin="https://www.linkedin.com/in/ainsley-maclean-m-d-162b3636/" />
      <TeamMemberCard name="Person6" title="Consultant" bio="Bio for Person6 goes here." email="ainsleymaclean@gmail.com" linkedin="https://www.linkedin.com/in/ainsley-maclean-m-d-162b3636/" />
      <TeamMemberCard name="Person7" title="Consultant" bio="Bio for Person7 goes here." email="ainsleymaclean@gmail.com" linkedin="https://www.linkedin.com/in/ainsley-maclean-m-d-162b3636/" />
      <TeamMemberCard name="Person8" title="Consultant" bio="Bio for Person8 goes here." email="ainsleymaclean@gmail.com" linkedin="https://www.linkedin.com/in/ainsley-maclean-m-d-162b3636/" />
    </div>
  </div>
);

export default About;
